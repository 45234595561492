// networks.js
const networks = {
    scimatic: {
        name: "Scimatic",
        chainId: "0x1e1", 
       // contractAddress: "0xEBc60F6899bA755b17653D49AD945a0560807692",
        contractAddress: "0x4aADED4d6c9882D7d0D9c1a873ab1FB585B73f82",
        
        Coin: "SCI",
        tokens: {
            USDT: "0x65C4A0dA0416d1262DbC04BeE524c804205B92e8",
            TRYS: "0xE73206041912228C2757372ecD39F88d940dc313",
            PKRS: "0x47b4Ea218257E8f952D7930d3ad3894A20117Bc6",
            INRS: "0x9778cE4eBD3bd9de3e21DCd45176287BEa7ef4f2",
            PHPS: "0xff7eF20450853f7Ca7c2e6316fD69e56a4A80081",
        },
    },
    ethereum: {
        name: "Ethereum",
        chainId: "0x1", 
       // contractAddress: "0x118eeDed051c5587eA78FB0c081Ad929d73c6e96",
        contractAddress: "0x18d5aCA70A3F89Fe535Aa9Be086AFc50Dcf46c00",
        
        Coin: "ETH",
        tokens: {
            USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        },
    },
    binance: {
        name: "Binance",
        chainId: "0x38", // Binance Smart Chain Mainnet
       // contractAddress: "0x0d156E72c400f682eD793e6c3a973230cB7139E6",
        contractAddress: "0x0eDDe56AC311fd1688E0D474779F39062105C3cA",
        Coin: "BNB",
        tokens: {
            USDT: "0x55d398326f99059fF775485246999027B3197955",
            USDC: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
            INRx: "0x4B3F37ED3FB1e60492C909F2746488778fA3A90b"
        },
    },
};

export default networks;
